import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "./layouts/Layout";
import LoginView from "./views/Auth/LoginView";
import Equips from "./views/Equips/Equips";
import Home from "./views/Home/Home";
import Incidences from "./views/Incidences/Incidences";
import Posts from "./views/Posts/Posts";
import Punts from "./views/Punts/Punts";
import AddPost from "./views/Posts/AddPost";

const routes = (logged) => [
	{
		path: "/",
		element: logged ? <Layout /> : <Navigate to="/login" />,
		children: [
			{ path: "/", element: <Home /> },
			{ path: "/equips", element: <Equips /> },
			{ path: "/posts", element: <Posts /> },
			{ path: "/posts/add", element: <AddPost /> },
			{ path: "/punts", element: <Punts /> },
			{ path: "/incidencies", element: <Incidences /> },
			// { path: '/botiga', element: <Botiga /> },
			// { path: '/botiga/nou', element: <ProducteAdd /> },
			// { path: '/botiga/:id', element: <ProducteEdit /> },
			// { path: '/incidencies', element: <Incidencies /> },
			// { path: '/faqs', element: <FAQs /> },
			// { path: '/faqs/nou', element: <FAQsAdd /> },
			// { path: '/faqs/:id', element: <FAQsEdit /> },
			// { path: '/*', element: <NotFound /> },
		],
	},

	{
		path: "/login",
		element: !logged ? <LoginView /> : <Navigate to="/" />,
	},
];

export default routes;
