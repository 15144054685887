import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	Typography,
} from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { eliminarPost, getElement, publicar } from "../../../database/API";
import Stars from "../../../components/Stars";
import { useSnackbar } from "notistack";

export default function DialogPost({ open, setOpen, id, setUpdate }) {
	const classes = useStyles();
	const [post, setPost] = useState([]);
	const [loading, setLoading] = useState(true);
	const [estrelles, setEstrelles] = useState(0);
	const [publicat, setPublicat] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const { result } = await getElement("post", id);
			setEstrelles(Number(result?.estrelles));
			setPublicat(!Number(result?.revisio));
			setPost(result);
			setLoading(false);
		};

		get();
	}, [id]);

	const guardar = async () => {
		const message = await publicar(publicat, estrelles, post?.id);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	const eliminar = async () => {
		const message = await eliminarPost(post?.id, post?.equip?.num_reserva);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
		>
			<DialogTitle id="alert-dialog-title">
				Revisar publicació
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<CircularProgress />
				) : (
					<Box>
						<Box
							display={"flex"}
							alignItems="center"
							alignContent={"center"}
							mb={3}
						>
							<Avatar
								src={
									"https://greenchallenge.covcontrol.net/public/storage/" +
									post?.equip?.foto
								}
							/>
							<Typography variant="h3" ml={2}>
								{post?.equip?.nom}
							</Typography>
						</Box>
						<img
							src={
								"https://greenchallenge.covcontrol.net/public/storage/" +
								post?.foto
							}
							style={{
								borderRadius: 10,
								maxWidth: 500,
								width: "100%",
							}}
						/>
						<Typography variant="body1">
							<strong>Text:</strong> {post?.titol}
						</Typography>
						<Box
							mt={4}
							display="flex"
							justifyContent={"space-between"}
							alignItems="center"
						>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											defaultValue={Number(publicat)}
										/>
									}
									label="Revisat i publicat"
									checked={Number(publicat)}
									onChange={() => setPublicat(!publicat)}
								/>
							</FormGroup>
							<Stars
								stars={estrelles}
								setEstrelles={setEstrelles}
							/>
						</Box>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={eliminar} color="warning">
					Eliminar publicació
				</Button>
				<Button onClick={() => setOpen(false)} color="warning">
					Tancar
				</Button>
				<Button onClick={guardar} autoFocus>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DialogPost.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	setUpdate: PropTypes.func,
	id: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgb(15,89,45)",
		background: "linear-gradient(0deg, rgba(15,89,45,1) 0%, #4FB543 100%)",
		height: "100vh",
		width: "100%",
	},
}));
