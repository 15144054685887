import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { createPost } from "../../database/API";

export default function AddPost() {
	const [title, setTitle] = useState("");
	const [foto, setFoto] = useState();
	const { enqueueSnackbar } = useSnackbar();

	const enviar = async () => {
		const formData = new FormData();
		formData.append("titol", title);
		formData.append("equip_id", 1);
		formData.append("foto", foto[0], foto[0].name);
		console.log(formData.get("foto"));

		// const message = await createPost(data);
		// enqueueSnackbar(message, {
		// 	variant: "success",
		// });
	};

	return (
		<Box m={4}>
			<TextField
				label="Titol"
				type="text"
				variant="outlined"
				fullWidth
				value={title}
				onChange={(e) => setTitle(e.target.value)}
			/>
			<input
				type="file"
				name="foto"
				onChange={(e) => setFoto(e.target.files)}
			/>
			<Button onClick={enviar} color="warning">
				Enviar
			</Button>
		</Box>
	);
}
