import axios from "axios";

const url = "https://greenchallenge.covcontrol.net/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				console.log(response.data);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	console.log(user);
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.access_token,
		},
	};
	await axios
		.post(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, id) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element + "/" + id, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const publicar = async (publicat, estrelles, id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	const data = new FormData();
	data.append("publicat", publicat);
	data.append("estrelles", estrelles);

	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.access_token,
		},
	};
	await axios
		.post(url + "publicar/" + id, data, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Publicació actualitzada";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return message;
};

const eliminarPost = async (key, num) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.delete(url + "post/" + key + "/" + num, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Eliminat amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al eliminar";
			}
		});

	return message;
};

const createPost = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "post", values, config).then((response) => {
		if (response.status === 200) {
			message = "Post creat";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al crear";
		}
	});

	return message;
};

const createPunt = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "punt", values, config).then((response) => {
		if (response.status === 200) {
			message = "Material creat";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al crear";
		}
	});

	return message;
};
const updatePunt = async (values, id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "punt/" + id, values, config).then((response) => {
		if (response.status === 200) {
			message = "Material creat";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al crear";
		}
	});

	return message;
};
const eliminarPunt = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "punt/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al eliminar";
		}
	});

	return message;
};

const updateIncidence = async (values, id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios
		.post(url + "incidences/" + id, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Material creat";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al crear";
			}
		});

	return message;
};
const eliminarIncidence = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "incidences/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al eliminar";
		}
	});

	return message;
};

export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	publicar,
	eliminarPost,
	createPunt,
	updatePunt,
	eliminarPunt,
	updateIncidence,
	eliminarIncidence,
	createPost,
};
