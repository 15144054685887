import React from "react";
import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";
import { Check } from "@mui/icons-material";
import Stars from "../../../components/Stars";
import "moment/locale/ca";

const PostColumns = ({ posts, setPostID, setOpen }) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "foto",
			label: "Foto",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						variant="circular"
						src={
							value &&
							"https://greenchallenge.covcontrol.net/public/storage/" +
								value
						}
					/>
				),
			},
		},
		{
			name: "titol",
			label: "Text",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value.substring(0, 20) + "...",
			},
		},

		{
			name: "revisio",
			label: "Revisat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					String(value) === "0" ? (
						<Check />
					) : (
						<Typography variant="cursiva">En revisió...</Typography>
					),
			},
		},
		{
			name: "estrelles",
			label: "Estrelles",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <Stars stars={value} />,
			},
		},
		{
			name: "equip.nom",
			label: "Equip",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "created_at",
			label: "Data publicació",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					moment(value).locale("ca").fromNow(),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Revisar i modificar">
							<IconButton
								onClick={() => {
									setOpen(true);
									setPostID(posts[dataIndex].id);
								}}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default PostColumns;
